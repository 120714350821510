

const AccountDetailsTable = () => import("@/components/account/AccountDetailsTable.vue").then(m => m.default || m)

import Vue from "vue"
import { AccountItem } from "@/types/responses"
export default Vue.extend({
	name: "RemoveUserDialog",
	components:{
		AccountDetailsTable,
	},
	props: {
		removing: {
			type: Boolean,
		}
	},
	computed: {
		getSelectedAccount(): AccountItem {
        	return this.$store.state.context.selectedAccount
		}
	},
	data(): {
		confirmRemove: string
		removeLoading: boolean
	} {
		return {
			confirmRemove: "",
			removeLoading: false
		}
	},
	methods: {
		closeLog(): void {
			this.$emit("close", null)
		},

		async removeAccount(personId: string): Promise<void> {

			try {
				console.log("REMOVE ACCOUNT CONFIRMED")
				console.log("REMOVING: " + this.getSelectedAccount.userId)
				this.removeLoading = true
				await this.$vStore.dispatch("context/removeAccount", personId)

				//Remove account from Store
				let results: Array<AccountItem> = this.$store.state.context.findPersonAccounts
				results = results.filter(account => account.userId !== this.getSelectedAccount.userId)
				//Update with new results
				this.$vStore.dispatch("context/updateAccountResults", results)


				this.$emit("itemRemoved", personId)
				
			} catch (e) {
				const errormessage = "Something went wrong, unable to remove account. Error: " + JSON.stringify(e)

				console.warn("Something went wrong, unable to remove account. Error: ", e)
				this.$emit("itemRemoved", errormessage )
			}

			this.removeLoading = false
			
			this.closeLog()
		}
	},
})
